import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'

var httpClient = new HttpClient(ApiGateway.Pas);

export default {
    getPaymentByPaymentID(paymentID) {
        return httpClient.get(`/PaymentSummary/Get/${paymentID}`, {});
    },
    getPaymentByEpisodeRecordID(episodeRecordID) {
        var query = {
            episodeRecordID
        }
        return httpClient.get(`/PaymentSummary/GetByEpisodeRecord/{episodeRecordID}`, query);
    },
}