<template>
  <payment-receipt
    ref="PaymentReceipt"
    :episodeRecordID="episodeRecordID"
    :patientID="patientID"
    :clinicID="clinicID"
  />
</template>

<script>
import PaymentReceipt from "@/components/payment/PaymentReceipt.vue";

export default {
  components: {
    PaymentReceipt,
  },
  data() {
    return {
      episodeRecordID: parseInt(this.$route.params.episodeRecordID),
      patientID: parseInt(this.$route.params.patientId),
      clinicID: parseInt(this.$store.getters.userData.clinicID),
    };
  },
  mounted() {
    this.print();
  },
  methods: {
    print() {
      this.$refs.PaymentReceipt.print();
    },
  },
};
</script>