<template>
  <div class="d-print-block justify-center">
    <v-row class="mp-0 mt-1">
      <v-col cols="2" class="mp-0 text-right">Payment:</v-col>
      <v-col cols="4" class="mp-0 text-left">{{
        paymentMethods[payment.paymentMethod - 1] || "Not paid yet."
      }}</v-col>
      <v-col cols="2" class="mp-0 text-right">Detail:</v-col>
      <v-col cols="4" class="mp-0 text-left">{{
        payment.paymentMethodDescription || "N/A"
      }}</v-col>
      <v-col cols="2" class="mp-0 text-right">Note:</v-col>
      <v-col cols="10" class="mp-0 text-left">{{
        payment.note || "N/A"
      }}</v-col>
    </v-row>
    <table class="mt-4">
      <tbody>
        <tr>
          <th class="text-center">#</th>
          <th class="text-center mw-120">Service Name</th>
          <th class="text-center">Qty</th>
          <th class="text-center">Price</th>
          <th class="text-center">Total</th>
        </tr>
        <tr v-for="item in payment.items" :key="item.order">
          <td class="text-center sub">{{ item.order }}</td>
          <td class="mw-120 sub">{{ item.itemName }}</td>
          <td class="text-center sub">{{ item.quantity }}</td>
          <td class="text-right sub">{{ item.priceString }}</td>
          <td class="text-right sub">{{ item.finalPriceString }}</td>
        </tr>
        <tr>
          <td colspan="4" class="text-right sub">Total Price:</td>
          <td class="text-right sub">{{ payment.totalPriceString }}</td>
        </tr>
        <tr>
          <td colspan="4" class="text-right sub">Additional Price:</td>
          <td class="text-right sub">{{ payment.additionalPriceString }}</td>
        </tr>
        <tr>
          <td colspan="4" class="text-right sub">Promotion Price:</td>
          <td class="text-right sub">{{ payment.promotionPriceString }}</td>
        </tr>
        <tr>
          <td colspan="4" class="text-right sub">Adjustment Price:</td>
          <td class="text-right sub">{{ payment.adjustmentPriceString }}</td>
        </tr>
        <tr>
          <td colspan="4" class="text-right sub">Final Price:</td>
          <td class="text-right sub">{{ payment.finalPriceString }}</td>
        </tr>
        <tr>
          <td colspan="4" class="text-right sub">Cash Advance:</td>
          <td class="text-right sub">
            {{ payment.customerCashAdvanceString }}
          </td>
        </tr>
        <tr>
          <td colspan="4" class="text-right sub">Cash Return:</td>
          <td class="text-right sub">
            {{ payment.customerCashChangeString }}
          </td>
        </tr>
      </tbody>
    </table>
    <v-row class="mp-0 mt-2">
      <v-col cols="6" class="mp-0 text-center"></v-col>
      <v-col cols="6" class="mp-0 text-center sub"
        ><i>{{ payment.dateConfirmString }}</i></v-col
      >
    </v-row>
    <v-row class="mp-0 mt-2">
      <v-col cols="6" class="mp-0 text-center"></v-col>
      <v-col cols="6" class="mp-0 text-center">
        <p><b>Receptionist</b></p>
        <p class="mt-15">{{ receptionistName }}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import PaymentService from "@/services/payment";
import { awaitAll, convertPriceString } from "@/plugins/helper";
import { PaymentMethodType } from "@/plugins/constant";

export default {
  props: {
    episodeRecordID: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      paymentMethods: Object.keys(PaymentMethodType),
      payment: {
        items: [],
      },
      receptionistName: this.$store.getters.userData.fullName,
    };
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    formatDate(date) {
      if (!date) return "";
      return moment(date).format("DD-MM-YYYY");
    },
    async print() {
      await awaitAll([this.renderPaymentDetail()]);
      window.print();
    },
    async renderPaymentDetail() {
      var result;
      if (this.paymentID) {
        result = await PaymentService.getPaymentByPaymentID(this.paymentID);
      } else {
        result = await PaymentService.getPaymentByEpisodeRecordID(
          this.episodeRecordID
        );
      }
      if (!result || result.error) {
        this.showError("Cannot get payment summary. Please try again later!");
        return;
      }
      this.payment = result;
      this.payment.dateConfirmString = (this.payment.dateConfirmed
        ? moment(this.payment.dateConfirmed)
        : moment()
      ).format("MMMM Do YYYY, h:mm a");
      this.payment.additionalPriceString = convertPriceString(
        result.additionalPrice,
        false
      );
      this.payment.totalPriceString = convertPriceString(
        result.originalPrice,
        false
      );
      this.payment.promotionPriceString = convertPriceString(
        result.promotionPrice,
        false,
        true
      );
      this.payment.adjustmentPriceString = convertPriceString(
        result.adjustmentPrice,
        false
      );
      this.payment.finalPriceString = convertPriceString(
        result.finalPrice,
        false
      );
      this.payment.customerCashChangeString = convertPriceString(
        result.customerCashChange,
        false
      );
      this.payment.customerCashAdvanceString = convertPriceString(
        result.customerCashAdvance,
        false
      );
      for (var i = 0; i < this.payment.items.length; i++) {
        var item = this.payment.items[i];
        delete item.childrenItems;
        item.order = i + 1;
        item.quantity = 1;
        item.priceString = convertPriceString(item.currentPrice, false);
        item.finalPriceString = item.isFree ? "FREE" : item.priceString;
      }
      if (this.payment.staffConfirmFullName) {
        this.receptionistName = this.payment.staffConfirmFullName;
      } else {
        this.receptionistName = this.$store.getters.userData.fullName;
      }
    },
  },
};
</script>
<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid black;
}
table > tbody > tr > th {
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-top: 1px solid black;
  padding: 4px;
  text-align: left;
  background-color: white;
  color: black;
}
table > tbody > tr > th:first-child {
  border-left: 1px solid black;
}
table > tbody > tr > th:last-child {
  border-right: 1px solid black;
}
table > tbody > tr > td {
  border-bottom: 1px solid;
  border-left: 1px solid;
  padding: 2px;
}
table > tbody > tr:nth-last-child(2) > td {
  border-bottom: none;
}
table > tbody > tr:nth-last-child(5) > td {
  border-bottom: none;
}
table > tbody > tr:nth-last-child(6) > td {
  border-bottom: none;
}
table > tbody > tr > td:last-child {
  border-right: 1px solid;
}
@media print {
  * {
    font-size: 10px;
    font-family: Arial, Helvetica, sans-serif !important;
  }
  .title {
    font-size: 24px !important;
  }
  .title-2 {
    font-size: 18px !important;
    font-weight: bold;
  }
  .sub {
    font-size: 10px;
  }
  .mp-0 {
    margin: 0px;
    padding: 0px;
  }
  .text-right {
    padding-right: 10px;
  }
  .text-left {
    width: 100%;
    display: inline-block;
    border-bottom: 1px dotted #bdbdbd;
    padding-bottom: 2px;
  }
  .mw-120 {
    min-width: 120px;
    max-width: 160px;
  }
}
</style>